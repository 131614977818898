/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    strong: "strong",
    ul: "ul",
    li: "li",
    code: "code",
    h3: "h3",
    em: "em",
    ol: "ol"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I was following an ", React.createElement(_components.a, {
    href: "https://www.udemy.com/course/the-complete-ruby-on-rails-developer-course/"
  }, "online course"), " and came up with an issue caused by the difference in bootstrap versions between the one I was using and the one the instructor was using. I'm sure the easiest way to fix it was to install the right version, but I was taking the course to learn something and I wanted to set some ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Desirable_difficulty"
  }, "desirable difficulties"), " for best results, so I gave myself a challenge."), "\n", React.createElement(_components.p, null, "After doing some online research and looking through many forums I finally found something that worked for me."), "\n", React.createElement(_components.h2, {
    id: "the-issue",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-issue",
    "aria-label": "the issue permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Issue"), "\n", React.createElement(_components.p, null, "This is a rails application, which uses gems. Therefore my thought process was to just install the Bootstrap gem version of my choice and expect it to work. ", React.createElement(_components.strong, null, "It didn't"), ". I did manage to make the CSS part work and make the website look decent, but I couldn't get the menu dropdown to work, which relied on JavaScript."), "\n", React.createElement(_components.h2, {
    id: "heres-what-you-need",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#heres-what-you-need",
    "aria-label": "heres what you need permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Here's What You Need"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Important:"), " This was managed with the following versions:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "ruby 2.7.6")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "rails 6.1.6.1")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "@popperjs/core ^2.11.6")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "bootstrap 5.0.0")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "node 12.22.12")), "\n"), "\n", React.createElement(_components.h3, {
    id: "1-install-what-you-need",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-install-what-you-need",
    "aria-label": "1 install what you need permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Install what you need"), "\n", React.createElement(_components.p, null, "First of all, you don't need any gems. Just install ", React.createElement(_components.a, {
    href: "https://getbootstrap.com/docs/5.0/getting-started/introduction/"
  }, "bootstrap 5"), " and ", React.createElement(_components.a, {
    href: "https://popper.js.org/"
  }, "popper.js"), " with the following:\n", React.createElement(_components.code, null, "yarn add @popperjs/core bootstrap@5.0.0"), ".\nI'm sure you could use npm instead."), "\n", React.createElement(_components.p, null, "But wait! what's ", React.createElement(_components.code, null, "@popperjs/core"), "? Where did that come from? Well, for Bootstrap's JavaScript, you need popper.js because Bootstrap relies on it for positioning popovers. It's a dependency. You should end up with the screenshot below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 650px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/60b8f/package-json.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 43.558282208588956%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABJ0AAASdAHeZh94AAAA7ElEQVQoz52RvW6DQBCEeZrYcAcccD8cxxnsIDlpLDlprFR5gUR5/+aLcKxI7sDFaIvVfjOjTU6fJz5+3rl8v3H5OlO5nqz0CPWYEiUcLnPIvKVQPbJxbKXmKa3ZiGa1kl2cOIcDL+3I6PbUlae0HmU70tywEfpqsFRJUbYc7cBkIs82cggDtQs0ISCVu7quAsrCsjeRg450pqfxgUL7W7obTOjF4GQ+nOyOQfeUxuPGiKzae5hcnjIpq45R95i6Q2lP3rQIZVdX/QfOT3h1I3sb2blIVQXSwtxXXgPMlCeTGiHNdc7a5n/LR4C/VQIa4b0/31AAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image of package.json\"\n        title=\"image of package.json\"\n        src=\"/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/a6d36/package-json.png\"\n        srcset=\"/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/222b7/package-json.png 163w,\n/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/ff46a/package-json.png 325w,\n/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/a6d36/package-json.png 650w,\n/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/e548f/package-json.png 975w,\n/static/4ef70a6d2b02ce15ea3f5a6495ca0b48/60b8f/package-json.png 1217w\"\n        sizes=\"(max-width: 650px) 100vw, 650px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "\nMake sure both popper and bootstrap are installed."), "\n", React.createElement(_components.h3, {
    id: "2-add-code",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-add-code",
    "aria-label": "2 add code permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Add code"), "\n", React.createElement(_components.p, null, "Next, add ", React.createElement(_components.code, null, "*= require bootstrap"), " in ", React.createElement(_components.em, null, "application.css"), " like below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 650px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/9e580b33e594084d414dc41e06965629/1320e/application-css.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 38.65030674846626%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABJ0AAASdAHeZh94AAABEUlEQVQoz32Q20rEMBCG8zDaNM2hzaHd9CQYhL1Si+wTiOj7v8CMJE2WrixefMw3/yRDCLl8b7h9vuHH1ztefjZ0yxMy6ZF3HpvWI29z7SJj8nuUGaFswMJDZbHmPTZqQCZ75O0peeToZf7Xa+GQSDOhshkzodQjiu6IR6FHlHpKHuv+2t3jMtoYrBqDlNt9oTRzGkZUcZO55tPNGXW4U4jLieg8KDOC0hO0dkkoO0PrVlBmvmaRzq0g9Qiiy2SPmdQTMNkDUW4FcXoBOTwDUwNQbqEWLlE81lvMnX7PyLCeUS2vqOeAqvfpY+NfJI6eifP/IDU34bESoaplYNKFWrhAuQ1UuMC43Xux5wWa83vZL6dd7GoYPs/sAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image of application.css\"\n        title=\"image of application.css\"\n        src=\"/static/9e580b33e594084d414dc41e06965629/a6d36/application-css.png\"\n        srcset=\"/static/9e580b33e594084d414dc41e06965629/222b7/application-css.png 163w,\n/static/9e580b33e594084d414dc41e06965629/ff46a/application-css.png 325w,\n/static/9e580b33e594084d414dc41e06965629/a6d36/application-css.png 650w,\n/static/9e580b33e594084d414dc41e06965629/e548f/application-css.png 975w,\n/static/9e580b33e594084d414dc41e06965629/1320e/application-css.png 1292w\"\n        sizes=\"(max-width: 650px) 100vw, 650px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "\nAdd one line to application.css"), "\n", React.createElement(_components.p, null, "And another line in ", React.createElement(_components.em, null, "application.js"), " to use popper.js like the image below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 650px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/7eda9094f6351d8b36dc816c38297242/1132d/application-js.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 34.96932515337423%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABJ0AAASdAHeZh94AAABEUlEQVQoz32QTU+EMBRF+TXAFNpCW8pQCoOjGMm482Mm48LEvdGNC3/9NbwSExPGxcl5ecl7ublRYXuc3h/x+nXC+eMJL5/PePs+4+54AJMOQnfg2pNz1dLM1V+yogGTgWjDKzC+RSZcMG9ozqWDNB5S+2DjIVRLSB0Qi3npaM+ERcRkjbxsIJQjZ0UNrmYaMFEjzQwSQpPTfJ052OyIqQ7l/gG2HVH3E6rdBLvbww4DlGuRlxZZUSEjWzoKz9eJbD/BjEco18GP1zBu+O1LVA24DomFqWn+LyUltO0NqvYWoh4grMeGa8SsRMIUYqaQME3Ei9NcLzXo9YdzmX6Y4K8OMP09xHYE49XFJGGvLyb8AdAq4BaeRed5AAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image of application.js\"\n        title=\"image of application.js\"\n        src=\"/static/7eda9094f6351d8b36dc816c38297242/a6d36/application-js.png\"\n        srcset=\"/static/7eda9094f6351d8b36dc816c38297242/222b7/application-js.png 163w,\n/static/7eda9094f6351d8b36dc816c38297242/ff46a/application-js.png 325w,\n/static/7eda9094f6351d8b36dc816c38297242/a6d36/application-js.png 650w,\n/static/7eda9094f6351d8b36dc816c38297242/e548f/application-js.png 975w,\n/static/7eda9094f6351d8b36dc816c38297242/1132d/application-js.png 1158w\"\n        sizes=\"(max-width: 650px) 100vw, 650px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "\nImport bootstrap in application.js"), "\n", React.createElement(_components.p, null, "Great! Now you're ready to use Bootstrap with Rails 6."), "\n", React.createElement(_components.h3, {
    id: "3-a-possible-next-step",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-a-possible-next-step",
    "aria-label": "3 a possible next step permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. A possible next step"), "\n", React.createElement(_components.p, null, "In some cases, you may want to override the default styling from Bootstrap. The next section will talk about this and will assume you've done the ", React.createElement(_components.code, null, "yarn add"), " the two packages, as well as, the versions used are the same."), "\n", React.createElement(_components.h2, {
    id: "override-bootstrap-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#override-bootstrap-styles",
    "aria-label": "override bootstrap styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Override Bootstrap Styles"), "\n", React.createElement(_components.p, null, "This will show you one way to override bootstrap styles without relying on ", React.createElement(_components.code, null, "!important"), "."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Add ", React.createElement(_components.code, null, "gem 'bootstrap', '~> 5.0.0'"), " to Gemfile, then ", React.createElement(_components.code, null, "bundle install")), "\n", React.createElement(_components.li, null, "Remove ", React.createElement(_components.code, null, "*= require bootstrap"), " from ", React.createElement(_components.em, null, "assets/stylesheets/application.css"), ", which we added originally. This should have removed bootstrap styles if you check your app."), "\n", React.createElement(_components.li, null, "Create an scss file in the same directory like so ", React.createElement(_components.em, null, "assets/stylesheets/custom.css.scss"), ". You can name this file anything except ", React.createElement(_components.em, null, "application"), " and ", React.createElement(_components.em, null, "bootstrap"), ", otherwise, it'll cause a ", React.createElement(_components.strong, null, "Sprockets::DoubleLinkError"), " and ", React.createElement(_components.strong, null, "SassC::SyntaxError"), ", respectively."), "\n", React.createElement(_components.li, null, "Finally, add this line ", React.createElement(_components.code, null, "@import \"bootstrap\";"), " to the scss file, and check your app to see bootstrap styles return. Now you should be able to override bootstrap styles in the file without using ", React.createElement(_components.code, null, "!important"), "."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 650px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/3398759dd8b61eb7ec3d1b1df841f3c5/ef916/custom-bootstrap-css-scss.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 22.699386503067483%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABJ0AAASdAHeZh94AAAAwklEQVQY04XOy2rCQBSA4XkXo5M4amISGzVWRQziRlAoddNWIi4Eg1q66Bu0qd0UH/iXyUJKCLr4OHc44vO85f1nwz6NSdI3dl+vmSRdkXyvOJxijr8xH38bFus5yu5htwY0/D721eOVeHp+YTZdEoYR7VGf3mRINxoQjoe4QYj90MYJtA51L0AqD1nzkaqYMGSTknQwTAdDuhiVJrqnlS2XiuVmMcurHmbNx1Q6FhPZwn/1XJ2jv7g1F1J5uYPb9T0XBrqk240CZYUAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image of custom_bootstrap.css.scss\"\n        title=\"image of custom_bootstrap.css.scss\"\n        src=\"/static/3398759dd8b61eb7ec3d1b1df841f3c5/a6d36/custom-bootstrap-css-scss.png\"\n        srcset=\"/static/3398759dd8b61eb7ec3d1b1df841f3c5/222b7/custom-bootstrap-css-scss.png 163w,\n/static/3398759dd8b61eb7ec3d1b1df841f3c5/ff46a/custom-bootstrap-css-scss.png 325w,\n/static/3398759dd8b61eb7ec3d1b1df841f3c5/a6d36/custom-bootstrap-css-scss.png 650w,\n/static/3398759dd8b61eb7ec3d1b1df841f3c5/ef916/custom-bootstrap-css-scss.png 815w\"\n        sizes=\"(max-width: 650px) 100vw, 650px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "\nAdd your bootstrap override classes after the import line."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
