import GATSBY_COMPILED_MDX from "/opt/build/repo/content/posts/2022-08-24-add-bootstrap-5-to-rails-6/index.mdx";
import * as React from "react";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import MDXComponents from "../components/MDXComponents";
import Seo from "../components/SEO";
const Post = ({data: {mdx}, children}) => {
  const post = mdx;
  return React.createElement("article", null, React.createElement("header", {
    className: "text-teal-50 bg-gradient-to-tr from-teal-900 to-teal-500"
  }, React.createElement("div", {
    className: "px-3 py-10 max-w-screen-xl m-auto relative"
  }, React.createElement("div", {
    className: "z-20 relative"
  }, React.createElement("h1", {
    className: "text-3xl md:text-6xl font-semibold pb-5 md:pb-8"
  }, post.frontmatter.title), post.frontmatter.date && React.createElement("p", {
    className: "text-xl md:text-3xl pb-3 md:pb-6"
  }, "Updated on ", post.frontmatter.date)))), React.createElement("div", {
    className: "max-w-screen-xl m-auto"
  }, React.createElement("div", {
    className: "px-3 py-10 max-w-prose m-auto md:text-xl"
  }, React.createElement(MDXProvider, {
    components: MDXComponents
  }, children))));
};
export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        date
        title
      }
    }
  }
`;
Post
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data: {mdx: {frontmatter: {title}}}}) => React.createElement(Seo, {
  pageTitle: title
});
